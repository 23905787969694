import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconComponent } from '@app/+authenticated/shared/icon.component';
import { DecimalPipe } from '@app/pipes/decimal.pipe';

import { DecimalInputDirective } from '../directives/decimalInput.directive';

export const DECIMAL_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DecimalInputComponent),
  multi: true,
};

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, IconComponent, DecimalInputDirective],
  selector: 'decimal-input',
  templateUrl: './decimal-input.component.html',
  providers: [DECIMAL_INPUT_CONTROL_VALUE_ACCESSOR, DecimalPipe],
})
export class DecimalInputComponent implements ControlValueAccessor {
  @Input()
  public decimals = 2;
  @Input()
  public step = 1;
  @Input()
  public icon: string;
  @Input()
  public fullWidth = false;
  @Input()
  public addOn: string = null;
  @Input()
  public defaultValue = '0';
  @Input()
  public clickable: boolean;
  @Input()
  public inputId: string;

  public displayValue: string;
  public value: string;
  public disabled: boolean;

  // internal functions to call when ControlValueAccessor
  // gets called
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  private onTouched: () => any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
  public onModelChange: (value: any) => void = () => {};

  public constructor(
    protected decimalPipe: DecimalPipe,
    private cd: ChangeDetectorRef,
  ) {}

  public incrementInteger(event: KeyboardEvent) {
    event.preventDefault();
    const value = parseFloat(this.value) + this.step;
    this.displayValue = this.transform(value.toFixed(this.decimals));
    this.onChange(value.toFixed(this.decimals));
  }

  public decrementInteger(event: KeyboardEvent) {
    event.preventDefault();
    const value = parseFloat(this.value) - this.step;
    this.displayValue = this.transform(value.toFixed(this.decimals));
    this.onChange(value.toFixed(this.decimals));
  }

  public onBlur() {
    this.displayValue = this.transform(this.value);
    this.onTouched();
  }

  // our custom onChange method
  public onChangeInput(displayValue: string) {
    this.displayValue = displayValue;
    const value = displayValue ? this.parse(displayValue) : this.defaultValue;

    this.onChange(value);
  }

  protected onChange(value: string) {
    this.value = value;
    this.onModelChange(value);
  }

  protected parse(displayValue: string) {
    return this.decimalPipe.parse(displayValue, this.decimals);
  }

  protected transform(value: string) {
    return value ? this.decimalPipe.transform(value, this.decimals, false) : this.defaultValue;
  }

  // called by the reactive form control
  // writes the value to the local component
  // that binds to the "value"
  public writeValue(value: string) {
    if (!value) {
      value = this.defaultValue;
    }

    this.value = value;
    this.displayValue = this.transform(this.value);
    this.cd.detectChanges();
  }

  // called by the reactive form control
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnChange(fn: (value: any) => void): void {
    this.onModelChange = fn;
  }

  // called by the reactive form control
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // called by the reactive form control
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled || undefined;
  }
}
